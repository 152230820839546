<template>
	<div class="flex items-center text-sm min-h-8">
		<div class="relative">
			<div
				v-click-outside="closeDropDown"
				class="flex items-center cursor-pointer group"
				tabindex="0"
				role="text"
				:aria-label="screenReaderText"
				@click="showHours = !showHours"
				@keydown.shift.tab="closeDropDown"
				@keydown.enter="showHours = !showHours"
			>
				<div
					:class="[
						isOpen ? 'bg-green-100 text-green-500': 'bg-gray-300 text-white',
						'flex px-2 h-6 items-center mr-2 text-xs font-bold uppercase md:text-sm rounded text-center '
					]"
				>
					{{ statusText }}
				</div>
				<div class="xl:group-hover:bg-gray-200 rounded-lg flex items-center pl-1.5">
					<div class="">
						{{ hoursToday }}
					</div>
					<div class="mx-2 -mt-0.5">
						<ArrowTriangle
							:class="[
								'transition-all ease-in',
								{ 'transform rotate-180': showHours }
							]"
						/>
					</div>
				</div>
			</div>
			<Transition name="slide-down">
				<ul
					v-if="showHours"
					class="absolute w-56 p-2 mt-2 text-sm list-none duration-100 bg-white border border-gray-300 rounded shadow z-1030"
					tabindex="0"
					aria-live="polite"
					@keydown.tab="closeDropDown"
				>
					<li
						v-for="(day, index) in businessHours"
						:key="index"
						class="p-1"
					>
						<strong class="inline-block w-12 mr-3 capitalize">
							{{ day.name }}
						</strong> {{ day.value }}
					</li>
				</ul>
			</Transition>
		</div>
	</div>
</template>

<script async>
import ArrowTriangle from '@/components/icons/ArrowTriangle.vue'
import { formatTimeString } from '@/utils/dateFormat.js'

const DAYS = [ 'Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat' ]

export default {
	components: {
		ArrowTriangle
	},
	props: {
		hours: {
			type: Array,
			required: true
		},
		isOpen: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			showHours: false,
			activeIndex: -1
		}
	},
	computed: {
		businessHours() {
			if (!this.hours?.length || !this.formattedBusinessHours[0]) return null
			const returnData = {}
			for (const day in DAYS) {
				returnData[day] = {
					name: DAYS[day],
					value: this.timePrint(this.formattedBusinessHours[day])
				}
			}
			return returnData
		},
		formattedBusinessHours() {
			return this.hours.map(day => {
				const openTime = formatTimeString(day.openTime)
				const closeTime = formatTimeString(day.closeTime)
				return {
					weekday: day.weekday,
					openTime,
					closeTime,
					closed: day.closed,
					hoursType: day.hoursType,
					open24: day.open24,
					displayTime: `${openTime} - ${closeTime}`
				}
			})
		},
		hasHours() {
			return !!this.hours?.length
		},
		hoursToday() {
			if (!this.hasHours) return

			const today = new Date().getDay()
			return this.timePrint(this.formattedBusinessHours[today])
		},
		statusText() {
			return this.isOpen ? 'Open' : 'Closed'
		},
		screenReaderText() {
			return `Hours Dropdown ${this.statusText} ${this.hoursToday}`
		}
	},
	methods: {
		timePrint(weekdayData) {
			if (!weekdayData) return ''
			if (weekdayData.closed) {
				return 'Closed'
			} else if (weekdayData.open24) {
				return 'Open 24 Hours'
			} else {
				return weekdayData.displayTime
			}
		},
		closeDropDown() {
			this.showHours = false
		},
		ariaLive(index) {
			return Number(index) === this.activeIndex ? 'polite' : 'off'
		},
		ariaHidden(index) {
			return Number(index) !== this.activeIndex
		}
	}
}
</script>

<style scoped>
.z-1030 {
	z-index: 1030;
}
</style>
